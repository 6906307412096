// =============================================================================
//
//  Layout States
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
//  IMPORTANT: THIS IS COPIED FROM ZOUND, PLEASE CHANGE WHEN NECESSARY
//==============================================================================

$state-animation-speed: 0.3s;
$state-animation-timing: ease;

// -----------------------------------------------------------------------------
//  General:
// -----------------------------------------------------------------------------

.state-container {
    position: fixed;
    z-index: 16;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $transparent;
    transition: transform $state-animation-speed $state-animation-timing $state-animation-speed;
}

.state-container__header {
    position: relative;
    //@extend %group;

    opacity: 0;
    transition: opacity $state-animation-speed ease;

    .cancel-states-trigger {
        float: right;
    }
}

.state-container__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    box-shadow: -#{$base-padding * 4.7} 0 $base-padding * 4.7 -#{$base-padding * 4.7} rgba(0, 0, 0, 0.93);
    transform: translate3d(0, 0, 0);
}

.state-container--loading {
    &::before,
    &::after {
        opacity: 1 !important;
        transition: opacity 0.2s ease, z-index 0.2s step-start;
    }

    &::before {
        z-index: 2 !important;
    }

    &::after {
        z-index: 1 !important;
    }
}

.state-container__scroll-message {
    display: none;
}

// -----------------------------------------------------------------------------
//  Overlay:
// -----------------------------------------------------------------------------

.overlay {
    position: fixed;
    z-index: 3;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.5);
    opacity: 0;
    transition: opacity $state-animation-speed $state-animation-timing, top $state-animation-speed step-end;
}

// -----------------------------------------------------------------------------
//  Specific State Containers:
// -----------------------------------------------------------------------------

.state-container--menu {
    .csstransforms & {
        transform: translateX(110%);
    }

    // stylelint-disable selector-class-pattern
    .csstransforms3d & {
        transform: translate3d(110%, 0, 0);
    }

    .no-csstransforms & {
        right: -110%;
        left: 110%;
    }
}

.state-container--wishlist {
    .csstransforms & {
        transform: translateX(110%);
    }

    .csstransforms3d & {
        transform: translate3d(110%, 0, 0);
    }

    .no-csstransforms & {
        right: -110%;
        left: 110%;
    }

    .state-container__content {
        background-color: $white;
    }
}

.state-container--refinements {
    .csstransforms & {
        transform: translateX(110%);
    }

    .csstransforms3d & {
        transform: translate3d(110%, 0, 0);
    }

    .no-csstransforms & {
        right: -110%;
        left: 110%;
    }
}

// -----------------------------------------------------------------------------
//  Menu State:
// -----------------------------------------------------------------------------

.state--menu {
    .state-container--menu {
        .csstransforms & {
            transform: translateX(30%);
        }

        .csstransforms3d & {
            transform: translate3d(30%, 0, 0);
        }

        .no-csstransforms & {
            right: 0;
            left: 30%;
        }

        .menu--item-link {
            opacity: 1;
            transform: none;
        }
    }
}

.state--refinements {
    .state-container--refinements {
        .csstransforms & {
            transform: translateX(10%);
        }

        .csstransforms3d & {
            transform: translate3d(10%, 0, 0);
        }

        .no-csstransforms & {
            right: 0;
            left: 10%;
        }

        .menu--item-link {
            opacity: 1;
            transform: none;
        }
    }
}

// -----------------------------------------------------------------------------
//  Whishlist State:
// -----------------------------------------------------------------------------

.state--wishlist {
    .state-container--wishlist {
        .csstransforms & {
            transform: translateX(5%);
        }

        .csstransforms3d & {
            transform: translate3d(5%, 0, 0);
        }

        .no-csstransforms & {
            right: 0;
            left: 5%;
        }
    }
}

// -----------------------------------------------------------------------------
//  Overlay:
// -----------------------------------------------------------------------------

.state--cart,
.state--menu,
.state--wishlist {
    .overlay {
        top: 0;
        opacity: 1;
        transition: opacity $state-animation-speed $state-animation-timing, top $state-animation-speed step-start;
    }

    .product-box__ribbon {
        opacity: 0;
    }
}

// -----------------------------------------------------------------------------
//  General active state stylings
// -----------------------------------------------------------------------------

.state--cart,
.state--menu,
.state--country-dropdown,
.state--wishlist,
.state--search,
.state--quickview,
.state--country-selector,
.state--refinements {
    overflow: hidden;
    height: 100%;
    -webkit-overflow-scrolling: auto;

    .state-container__header {
        opacity: 1;
        transition: opacity $state-animation-speed $state-animation-timing $state-animation-speed;
    }

    .header__button-group {
        opacity: 0;
        transition: opacity $state-animation-speed $state-animation-timing;
    }

    .state-container {
        transition: transform $state-animation-speed $state-animation-timing;
    }
}

.state--menu {
    position: fixed;
    height: 100vh;
}

.state--country-dropdown {
    overflow: auto;
}

html {
    &.country-dropdown-selector {
        overflow: hidden;
        height: 100%;
        -webkit-overflow-scrolling: auto;
    }
}
