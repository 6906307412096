// =============================================================================
//
//  Fonts
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

// -----------------------------------------------------------------------------
//  Icons:
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'bodystore-icons';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/icons/bodystore-icons.eot');
    src:url('../../fonts/icons/bodystore-icons.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/icons/bodystore-icons.woff') format('woff'),
        url('../../fonts/icons/bodystore-icons.ttf') format('truetype'),
        url('../../fonts/icons/bodystore-icons.svg#bodystore-icons') format('svg');
    font-display: swap;
}

// -----------------------------------------------------------------------------
//  Scene Font:
// -----------------------------------------------------------------------------

/**
 * @license
 * MyFonts Webfont Build ID 3907490, 2020-05-27T09:52:38-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: ScenePro-Black by Monotype
 * URL: https://www.myfonts.com/fonts/mti/scene/pro-black/
 *
 * Webfont: ScenePro-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/scene/pro-bold/
 *
 *
 * Webfonts copyright: Digitised data (c) 2002 Monotype Imaging, Inc. All Rights Reserved.
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3b9fa2');

@font-face {
    font-family: 'ScenePro-Bold';
    src: url('../../fonts/customFont/ScenePro-Bold/font.woff2') format('woff2'), url('../../fonts/customFont/ScenePro-Bold/font.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'ScenePro-Black';
    src: url('../../fonts/customFont/ScenePro-Black/font.woff2') format('woff2'), url('../../fonts/customFont/ScenePro-Black/font.woff') format('woff');
    font-display: swap;
}
