// =============================================================================
//
//  Cookie Banner Variables
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$cookie-banner__bottom: 0;
$cookie-banner__background-color: $grey;
$cookie-banner__font-color: $black;
$cookie-banner-link__color: $black;
$cookie-banner__width: 100%;
$cookie-banner-text__max-width: 500px;
$cookie-banner__padding: 0 ($base-padding * 1.8);
$cookie-banner__padding: 0 ($base-padding * 1.2);
$cookie-banner-text__position-right: 1.8rem;
$cookie-banner-icon__position-right: 4.8rem;
$cookie-banner-icon-padding: $base-padding;
$close-banner-icon__margin: 0 auto ($base-margin * 2) auto;
$close-banner-icon__height: 1.6rem;
$close-banner-icon__font-size: 1.6rem;
$cookie-banner-text__padding: ($base-padding * 1.8) 0;
$cookie-message-banner__line-height: 1.33;

$cookie-banner-message-banner__margin-left--large: $base-margin * 4.8;
$cookie-banner-message-banner__line-height--large: 1.33;
$close-banner-icon__font-size--large: 2.1rem;
$cookie-banner-text__padding--large: ($base-padding * 2.75) 0;
$cookie-banner-icon-close__height--large: 2.1rem;
