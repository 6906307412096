// =============================================================================
//
//  Dynamic Banner
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/dynamicBanner';

.dynamic-message-banner {
    position: relative;
    background-color: $dynamic-banner__background-color;

    .content-wrapper {
        max-width: $dynamic-banner-content-wrapper__max-width;
        padding: $dynamic-banner-content-wrapper__padding;
        margin: 0 auto;
        color: $grey;
        font-size: $dynamic-banner__font-size;
        font-weight: $dynamic-banner-content-wrapper__font-weight;
        text-align: center;

        p,
        a {
            margin: 0;
            font-size: $dynamic-banner__font-size;
            line-height: $dynamic-banner-content-wrapper__line-height;
        }
    }

    &.hide {
        display: none;
    }

    .close-banner-icon {
        position: absolute;
        top: 50%;
        right: $dynamic-banner-close-banner-icon__position-right;
        height: $dynamic-banner-close-banner-icon__height;
        cursor: pointer;
        transform: translateY(-50%);
    }

    .icon-close {
        color: $black;
        font-size: $dynamic-banner-icon-close__font-size;
    }
}

@import '../screens/medium/components/dynamicBanner';
