// =============================================================================
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@include media-screen-lg {
    .newsletter-container {
        .newsletter-message {
            @include get-font('small');
            width: 58%;
            margin-left: 42%;
        }

        .newsletter {
            padding-top: $newsletter__padding--large;
            padding-bottom: $newsletter__padding-bottom--large;

            &-description {
                flex-basis: $newsletter-description__flex-basis--large;

                &-title {
                    @include get-font('medium');

                    .newsletter-description-title-mobile-hidden {
                        display: inline;
                    }
                }
            }

            &-description-text {
                @include flexbox();
                @include align-items(center);
                @include get-font('compact');
                width: 42%;
                height: 6rem;
                padding-right: $newsletter-description-text__padding-right--large;
                margin-top: 0;
            }

            &-bottom {
                @include flexbox;
                @include flex-flow (row nowrap);
                @include align-content(flex-end);
                width: 100%;
                margin-top: $newsletter-bottom__margin-top--large;
            }

            &-form {
                @include flexbox();
                @include align-items(flex-start);
                width: 58%;
                margin: 0;

                &-input-container {
                    width: 100%;
                    max-width: 41rem;
                    margin-right: $newsletter-form-input__margin-right--large;
                }

                &-input {
                    height: $newsletter-input__height--large;
                }

                &-subscribe {
                    max-width: 30.4rem;
                    height: $newsletter-subscribe__height--large;
                    padding: 0;
                }
            }

        }
    }

    .footer-wrapper-collapsible .footer-wrapper-content.height-auto-lg {
        height: auto;
        padding-right: $footer-wrapper-content__padding-right--large;
        padding-bottom: 0;
    }

    .footer-wrapper-title {
        padding-right: $footer-wrapper-title__padding-right--large;
    }

    .footer-wrapper-content {
        &-li {
            line-height: $footer-wrapper-content__line-height--large;
        }

        &-link {
            color: $footer-wrapper-content-link__color--large;
        }
    }

    .footer-wrapper-collapsible .footer-wrapper-title {
        .title {
            @include get-font('compact');
            padding-right: 0;
            cursor: default;
            font-weight: $footer-wrapper-title__font-weight--large;

            &::after {
                display: none;
            }
        }
    }

    .footer {
        padding-top: 0;
        padding-bottom: 0;
        
        .footer-wrapper {
            @include flex-flow(row wrap);
            @include justify-content(center);
            padding-top: $footer-wrapper__padding-top--large;

            &.footer-cards {
                @include justify-content(flex-start);

                &-link {
                    margin: 0;
                    margin-right: $footer-cards-link__margin-right--large;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            &-collapsible {
                @include flex(0 0 25%);
                @include flex-grow(1);
                max-width: $footer-wrapper-collapsible__max-width;
                padding-bottom: $footer-wrapper-collapsible__padding-bottom--large;
                border-bottom: none;

                &.footer-wrapper-second {
                    max-width: 19rem;
                }

                &.footer-wrapper-third {
                    max-width: 17rem;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        
    }

    .footer-social-media {
        .footer-social-media__box {
            &.footer-social-media__box-logo {
                @include align-items(flex-end);
                margin-left: auto;

                .footer-logo-img {
                    width: $footer-logo__width--large;
                    height: $footer-logo__height--large;
                    margin-left: $base-margin * 6.5;
                }
            }
        } 
    }

    .footer-wrapper.footer-wrapper__flags {
        padding-bottom: 0;
        border-bottom: $footer-wrapper__flags__border-bottom--large;

        .footer-flags {
            @include flex(0 0 50%);
            border-bottom: none;
        }

        .footer-copyright {
            @include get-font('mini');
            @include flex(0 0 50%);
            @include flexbox;
            @include justify-content(flex-end);
            padding: 0;
            color: $footer-copyright__color--large;
        }
    }
}


@media screen and (min-width: $desktop-width) and (max-width: 1279px) {
    .newsletter {
        &-container {
            .newsletter-form-subscribe {
                flex: 0 0 20rem;
            }
        }
    }
}
