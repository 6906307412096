// =============================================================================
//
//  Product Tile Large
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@include media-screen-lg {
    .product {
        border: none;
        margin-bottom: $product__margin-bottom--large;

    }

    .promotion-wrapper {
        position: absolute;
        z-index: 5;
        top: 0;
        left: $product-tile-promotion-wrapper__left--large;

    }

    .product-tile {
        @include flex-flow(column nowrap);
        position: relative;
        height: 100%;
        padding: $product-tile__padding--large;
        border-bottom: $border solid $grey;

        &-promotions-right {
            margin-bottom: 0;

            .promotions-right {
                z-index: 5;

                &.promotions-sale {
                    top: $product-tile-promotions-sale__top--large;
                }
            }

            .promotion {
                @include get-font('tiny');
                min-width: $product-tile-promotion__min-width--large;
                padding: $product-tile-promotion__padding--large;
                margin: 0;

                &.sale {
                    background: $black;
                    color: $white;

                    &::after {
                        z-index: 99;
                        border-top-color: $black;
                    }
                }

                &.custom {
                    background: $black;
                    color: $white;

                    &::after {
                        z-index: 99;
                        border-top-color: $black;
                    }
                }

                &.new {
                    margin-left: 0;
                    background: $black;
                    color: $black;

                    &::after {
                        z-index: 99;
                        border-top-color: $black;
                    }
                }

                &:last-child {
                    border: none;
                }
            }
        }

        &-rating {
            height: $product-tile-rating__height;
            order: -1;
        }

        &-image__container {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }

        &-image {
            width: 100%;
            height: auto;
        }

        &-description,
        &-CTA {
            padding-top: 0;
        }

        &-description {
            margin: $product-tile-description__margin--large;
        }

        &-description-top {
            @include flexbox();
            @include justify-content(center);
        }

        &-description-bottom {
            display: block;
        }

        &-name {
            letter-spacing: $product-tile-name__letter-spacing--large;
            line-height: $product-tile-name__line-height--large;
        }

        &-brand {
            @include get-font('xsmall');
        }

        &-CTA {
            @include flex-flow(row wrap);
            @include align-items(end);
            margin-top: $product-tile-cta__margin-top--large;

            .price {
                text-align: left;

                .price-adjusted {
                    @include get-font('large');
                    color: $black;
                    line-height: 1;
                }

                .price-non-adjusted {
                    @include get-font('xsmall');
                    width: 100%;
                    line-height: 0.6;
                }

                .price-sales {
                    @include get-font('large');
                }
            }

            .product-tile-buy {
                @include get-font('xsmall');
                padding: $product-tile-buy__padding--large;
            }
        }
    }
}

@supports (-ms-ime-align: auto) {
    .product-tile {
        &-image__container {
            height: auto;
            padding-bottom: 0;
        }
    }
}
