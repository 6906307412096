// =============================================================================
//
//  Ribbon Variables
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$promotion__min-width: 6.5rem;
$promotion__padding: ($base-padding * 0.3) ($base-padding * 0.5);
$promotion__background-color: $black;
$promotion__color: $white;
$promotion__font-weight: $bold;

$promotion-after__border: 0.5rem solid transparent;
$promotion-after__border-top-color: $black;
$promotion-after__margin-bottom: -0.5rem;

$promotion__min-width--large: 14.3rem;
$promotion__padding--large: ($base-padding * 0.3) ($base-padding * 0.7);
$promotion-after__border-width--large: 1rem;
$promotion-after__margin-bottom--large: -1rem;
