// =============================================================================
//
//  Cookie Banner
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/cookieBanner';

div.cookie-details-wrapper,
div.cookie-policy-wrapper,
div.cookie-integrity-wrapper {
    background-color: $very-light-pink-two;
}

.cookie-banner-overlay {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;

    &.hide {
        display: none;
    }
}

.homepage {
    ~ .cookie-banner-overlay {
        background: rgba($black, 0.5);
    }
}

.cookie-message-banner {
    position: fixed;
    z-index: 101;
    bottom: $cookie-banner__bottom;
    width: $cookie-banner__width;
    max-height: 66vh;
    padding: $cookie-banner__padding;
    background-color: $cookie-banner__background-color;
    color: $cookie-banner__font-color;
    overflow-y: scroll;

    .content-wrapper {
        @include flexbox();
        @include flex-flow(column nowrap);
        @include justify-content(space-between);
        position: relative;
    }

    .message-banner {
        @include get-font('small');
        padding: $base-padding;
        line-height: $cookie-message-banner__line-height;

        &-row {
            @include flexbox();
            @include flex-flow(column-reverse nowrap);

            &.align-center {
                text-align: center;
            }
        }


        &-column {
            padding: $base-padding 0;

            &.col-two,
            &.col-four,
            &.col-six,
            &.col-ten {
                width: 100%;
            }
        }

        .save-cookie-button,
        .save-necessary-cookie-button {
            width: 100%;
            padding: ($base-padding * 2.5) $base-padding;
        }

        .save-necessary-cookie-button {
            color: $cookie-banner-link__color;
            background: transparent;
            border: $border solid $cookie-banner-link__color;
        }
    }

    p,
    a {
        @include get-font('xsmall');
        margin: 0;
        color: $cookie-banner-link__color;
        line-height: $cookie-message-banner__line-height;
    }

    p + p {
        margin-top: $base-margin;
    }

    a {
        text-decoration: underline;
        text-decoration-color: $cookie-banner-link__color;

        &[data-view-cookie-details] {
            display: inline-block;
            padding: $base-padding;
        }
    }

    h3 {
        @include get-font('medium-big');
    }

    h4 {
        margin: 0;
    }

    img {
        max-width: 100%;
    }

    &.hide {
        display: none;
    }

    .close-banner-icon {
        @include flexbox;
        @include flex-flow(row nowrap);
        @include align-items(center);
        margin: $close-banner-icon__margin;
        cursor: pointer;
    }

    .close-icon-text {
        @include get-font('small');
        margin: 0;
    }

    .icon-close {
        height: $close-banner-icon__height;
        padding-left: $cookie-banner-icon-padding;
        font-size: $close-banner-icon__font-size;
    }
}

.cookie-details-wrapper,
.cookie-policy-wrapper,
.cookie-integrity-wrapper {
    position: fixed;
    z-index: 102;
    bottom: $cookie-banner__bottom;
    width: $cookie-banner__width;
    max-height: 66vh;
    padding: $cookie-banner__padding;
    background-color: $cookie-banner__background-color;
    color: $cookie-banner__font-color;
    overflow-y: scroll;

    &.hide {
        display: none;
    }

    .collapsible-header > .title,
    .form-group .form-control-checkbox-title {
        margin: 0;
    }

    input[type='checkbox'] + .form-control-label {
        z-index: 1;
        width: 110%;
    }

    input[type='checkbox']:disabled + .form-control-label {
        cursor: pointer;
        pointer-events: initial;
    }

    input[type='checkbox']:disabled + .form-control-label::before {
        border: $border solid $grey;
        opacity: 1;
    }

    .banner-details-buttons {
        margin-bottom: $base-margin * 3;

        button {
            padding: $base-padding ($base-padding * 2);
            margin-right: $base-margin;
        }

        &-save {
            margin-top: $base-margin * 2;

            button {
                width: 100%;
                padding: $base-padding ($base-padding * 2);
            }
        }
    }

    .close-banner-icon {
        position: absolute;
        top: 2.5rem;
        right: 2rem;
        cursor: pointer;

        .icon-close {
            &::before {
                font-weight: bold !important; /* override default font awesome */
            }
        }
    }

    .content-wrapper {
        @include flexbox();
        @include flex-flow(column nowrap);
        @include justify-content(space-between);
        position: relative;
    }

    .accordion {
        .collapsible {
            &:nth-child(2) {
                border-top: 0;
            }

            &:nth-child(5) {
                border-bottom: 0;
            }
        }
    }

    .message-banner {
        @include get-font('small');
        padding: $base-padding;
        line-height: $cookie-message-banner__line-height;

        &-row {
            @include flexbox();
            @include flex-flow(column-reverse nowrap);
            
            &.align-center {
                text-align: center;
            }
        }


        &-column {
            padding: $base-padding 0;

            &.col-two,
            &.col-four,
            &.col-six,
            &.col-ten {
                width: 100%;
            }

            &.accordion {
                margin-top: $base-margin * 2;
            }
        }
    }

    p,
    a {
        @include get-font('xsmall');
        margin: 0;
        color: $cookie-banner-link__color;
        line-height: $cookie-message-banner__line-height;
    }

    a {
        text-decoration: underline;
        text-decoration-color: $cookie-banner-link__color;

        &[data-view-cookie-details] {
            display: inline-block;
            padding: $base-padding;
        }
    }

    h3 {
        @include get-font('medium-big');
        padding-bottom: $base-padding * 1.5;
    }

    h4 {
        margin: 0;
    }

    img {
        max-width: 100%;
    }
}

.cookie-policy-wrapper,
.cookie-integrity-wrapper {
    p {
        margin-bottom: $base-margin;
    }

    h4 {
        margin: 0 0 $base-margin;
    }
}

@import '../screens/large/components/cookieBanner';
@import '../screens/medium-large/components/cookieBanner';
