// =============================================================================
//
//  Product Recommendations Desktop
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .product-recommendation {
        &-grid {
            @include flexbox;
            @include flex-flow(row wrap);
            margin: $product-recommendation-grid__margin--large;

            .product {
                width: $product-recommendation-product__width--large;
                padding: $product-recommendation-product__padding--large;
            }
        }
    }
}
