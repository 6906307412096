// =============================================================================
//
//  Cookie Banner Tablet
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) and (max-width: $desktop-width-large) {
    .cookie-details-wrapper,
    .cookie-policy-wrapper,
    .cookie-integrity-wrapper,
    .cookie-message-banner {
        .message-banner {
            padding: ($base-padding * 2) ($base-padding * 2) ($base-padding * 2) ($base-padding * 2);
        }
    }
}
