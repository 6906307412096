// =============================================================================
//
//  Tabs Desktop
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .tabs {
        &-header {
            right: 0;
            left: 0;
            width: 100%;
            margin: 0;
        }

        &-header > .item {
            @include get-font('medium');
            padding: 0;
            margin-right: $base-margin * 3.5;
        }
    }
}
