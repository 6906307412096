// =============================================================================
//
//  Header Top Medium
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $tablet-width) {
    .header-top {
        &-icon-list {
            display: none;
        }
    }
}
