// =============================================================================
//
//  Collapsible Item
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/collapsibleItem';

.collapsible {
    border-top: $collapsible__border-top;
    border-bottom: $collapsible__border-bottom;
    margin: $collapsible__margin;

    // -------------------------------------------------------------------------
    //  Collapsible Item Header:
    // -------------------------------------------------------------------------

    &-header {
        cursor: pointer;
    }

    &-header > .title {
        @include get-font('compact');
        position: relative;
        display: block;
        padding: $collapsible-header-title__padding;
        color: $collapsible-header-title__color;
        font-weight: $bold;
        text-decoration: none;

        &::after {
            content: '\61';
            position: absolute;
            top: 50%;
            right: 0;
            font-family: 'bodystore-icons' !important;
            font-size: $collapsible-header-title-after__font-size;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal !important;
            font-variant: normal !important;
            font-weight: normal !important;
            line-height: $collapsible-header-title-after__line-height;
            speak: none;
            text-transform: none !important;
            transform: translateY(-50%);
        }
    }

    // -------------------------------------------------------------------------
    //  Collapsible Item Content:
    // -------------------------------------------------------------------------

    &-content {
        @include get-font('small');
        overflow: hidden;
        height: 0;
        line-height: $collapsible-content__line-height;
        transition: height 0.3s ease-in-out;
    }

    // -------------------------------------------------------------------------
    //  Collapsible Item Active:
    // -------------------------------------------------------------------------

    &.active {
        border-top-color: $grey;

        > .collapsible-header > .title {
            font-weight: $bold;

            &::after {
                content: '\77';
            }
        }

        > .collapsible-content {
            height: auto;
            padding-bottom: $collapsible-content-active__padding-bottom;
        }
    }
}
