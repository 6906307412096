// =============================================================================
//
//  Static Page Variables
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

$static-page-full__width: 100%;
$static-page__margin: 2rem 0;
$static-page__margin--large: 6rem 0 10rem;
$static-page-wrapper__padding: 0 1.8rem;
$static-page-wrapper__padding--large: $wrapper__padding--large;
$static-page-body__flex--large: 0 0 70%;
$static-page-menu__flex--large: 0 0 20%;
$static-page-menu__margin-right--large: 12.4rem;
$static-page-body-container__max-width--large: 70%;
$static-page-body-container__margin-top: $base-margin * 3;
$static-page-menu-sub-item__padding: 2rem 0;
$static-page-menu-sub-item__border-top: $border solid rgba($black , 0.1);
$static-page-menu-sub-item-active__border-color: $black;
$static-page-menu-sub-item-active__font-weight: $bold;
$static-page-menu-content-item__padding: $base-padding 0;
