// =============================================================================
//
//  Product Tile
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/productTiles';

.product {
    border-top: $product-tile__border-top;
    margin-bottom: $base-margin;
    
    &-item {
        height: 100%;
    }
}

.product-tile {
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(row);
    @include flex-flow(wrap);
    padding: $product-tile__padding;

    &-image__container {
        @include justify-content(center);
        width: 100%;
        background-color: $product-tile-image__container__background-color;
        text-align: center;
    }

    &-image {
        max-height: 26rem;
        text-indent: -9999px; // fix the problem with alt text when image is not loaded
    }

    &-description,
    &-CTA {
        padding-top: $product-tile-description-cta_padding-top;
    }

    &-description {
        @include flexbox();
        @include flex-flow(column nowrap);
        @include flex-grow(1);
        width: 100%;
        margin: $product-tile-description__margin;

        .promotion-wrapper {
            margin-bottom: $product-tile-promotions__margin-bottom;
        }

        .promotions-right {
            display: inline-block;
            border-right: $product-tile-promotions-right__border-right;

            &:last-child {
                border-right: 0;

                .promotion:not(.sale) {
                    margin-left: $product-tile-promotions-new__margin-left;
                }
            }

            .promotion {
                @include get-font('mini');
                display: inline;
                min-width: auto;
                padding: $product-tile-promotions-span__padding !important; /* to overwrite !important for padding that is set for general .promotion class */
                margin-right: 0;
                background: none;
                color: $product-tile-promotion-mobile__color;
                font-weight: normal;
                text-align: left;
                text-transform: none;

                &.sale {
                    color: $product-tile-promotion-sale-mobile__color;
                }
            }
        }
    }

    .link {
        text-decoration: none;
    }

    &-name {
        @include get-font('small');
        margin: 0;
        color: $product-tile-name-link__color;
        font-weight: $bold;
        letter-spacing: $product-tile-name__letter-spacing;
        text-align: center;
    }

    &-brand {
        @include get-font('mini');
        color: $product-tile-brand__color;
        text-decoration: none;
    }

    &-rating {
        margin-top: $product-tile-rating__margin-top;
    }

    &-CTA {
        @include flexbox();
        @include justify-content(space-between);
        @include flex-flow(column nowrap);
        @include align-items(flex-end);
        position: relative;
        min-width: $product-tile-cta__min-width;

        .price {
            @include flexbox;
            @include flex-flow(column nowrap);
            align-items: baseline;
            text-align: right;
            white-space: nowrap;

            .price-adjusted {
                @include get-font('medium-big');
                color: $product-tile-cta-price-adjusted__color;
                font-weight: $product-tile-cta-price-adjusted__font-weight;
                line-height: 1;
            }

            .price-non-adjusted {
                @include get-font('mini');
                width: 100%;
                color: $product-tile-cta-price-non-adjusted__color;
                line-height: 0.6;
                text-decoration: line-through;
            }

            .price-sales {
                @include get-font('medium-big');
                font-weight: $product-tile-cta-price-sales__font-weight;
            }
        }

        .product-tile-buy {
            padding: $product-tile-buy__padding;
            text-decoration: none;

            &:hover {
                color: $product-tile-button-quickview-hover__color;
            }
        }
    }
}

.portrait-view {
    .slick-slider,
    .product-recommendation-grid {
        .product-tile {
            border: 0;
            padding-left: 0;
        }
        
        .promotion-wrapper {
            left: 0;
        }
    }
}

.promotion * {
    padding: 0;
    margin: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

.promotion-wrapper {
    .promotion {
        display: $product-tile-promotion-wrapper-promotion__display;
        width: $product-tile-promotion-wrapper-promotion__width;
        padding-right: $product-tile-promotion-wrapper-promotion__padding-right;
        padding-left: $product-tile-promotion-wrapper-promotion__padding-left;
    }
}

@import '../components/productTilesMobileViews';

@import '../screens/large/components/productTiles';
