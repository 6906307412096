// =============================================================================
//
//  Header Menu Navigation Large
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width-x-large) {
    .menu {
        height: $header-navigation__height--large;

        &-open-overlay {
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: $header-navigation-open-overlay__width--large;
            height: $header-navigation-open-overlay__height--large;
            background-color: $header-navigation-open-overlay__background-color--large;
        }

        &-main-container {
            position: static;
            overflow: hidden;
            width: $header-elements-full__width;
            height: $header-elements-full__height;
            padding: 0;
            background-color: $header-navigation-main-container__background-color--large;
        }

        &-main {
            a {
                display: unset;
                color: $menu-main-a__color;
                font-size: $menu-main-a__font-size;
                font-weight: $normal-weight;
                text-decoration: none;

                &:hover {
                    color: $a-hover__color;
                }
            }
        }

        &-actions {
            display: none;
        }

        &-category-list {
            position: static;
        }

        &-category-level-1,
        &-category-level-2,
        &-pages-list {
            @include flexbox();
            @include flex-direction(row);
            @include flex-wrap(wrap);

            li {
                a {
                    &::after {
                        content: none;
                    }
                }
            }
        }

        &-category-level-1,
        &-pages-list {
            > li {
                position: static;

                &.last-child {
                    padding-right: 0;
                }
            }
        }


        &-level-1-content,
        &-category-level-1 {
            height: $header-elements-full__height;
        }

        &-pages-list {
            height: $header-elements-full__height;
            margin-left: $header-navigation-page-list__margin-left--large;

            > .menu-page-item {
                @include flexbox();
                @include align-items(center);


                > .menu-page-link {
                    @include flexbox();
                    height: $header-elements-full__height;
                    padding: 0 $header-elements-base__padding;
                    transition: $header-links__transition;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: $header-elements-base__padding;
                        display: block;
                        width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding * 2});
                        height: $header-navigation-border__height--large;
                        background-color: $header-navigation-main-container__background-color--large;
                        transition: $header-navigation-border__transition--large;
                    }

                    &:hover {
                        color: $header-navigation-item-hover__color--large;
                    }

                    &:hover::before {
                        background-color: $header-navigation-item-border__background-color--large;
                    }
                }
            }

            > .menu-page-item:first-child {
                > .menu-page-link {
                    position: relative;
                    padding: 0 $header-elements-base__padding;
                    font-size: $header-navigation-category-link__font-size--large;

                    &::before {
                        width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding * 2});
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: $header-navigation-right-section-separator__top--large;
                        right: -#{$header-navigation-right-section-separator__width--large / 2}; /* minus half of width in order to center separator */
                        left: auto;
                        width: $header-navigation-right-section-separator__width--large;
                        height: $header-navigation-right-section-separator__height--large;
                        background-color: $header-navigation-separator__background-color--large;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        &-category-level-1 {
            @include flex-wrap(nowrap);

            > li {
                @include flexbox();
                @include align-items(center);
                white-space: nowrap;


                // stylelint-disable max-nesting-depth
                &.selected {
                    > a {
                        color: $header-navigation-item-hover__color--large;

                        &::after {
                            background-color: $header-navigation-item-border__background-color--large;
                        }
                    }
                }

                a {
                    &:hover {
                        color: $header-navigation-item-hover__color--large;
                    }
                }

                &:hover {
                    .menu-level-2-container {
                        visibility: visible;
                        transition-delay: 0.3s;
                    }
                }

                > a {
                    @include flexbox();
                    height: $header-elements-full__height;
                    padding: 0;
                    font-size: $header-navigation-category-link__font-size--large;
                    font-weight: $header-navigation-first-categpry-level-link__font-weight--large;
                    transition: $header-links__transition;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        width: $header-elements-full__width;
                        height: $header-navigation-border__height--large;
                        background-color: $header-navigation-main-container__background-color--large;
                        transition: $header-navigation-border__transition--large;
                    }

                    &:hover::after {
                        background-color: $header-navigation-item-border__background-color--large;
                    }
                }
            }
        }

        &-category-level-1 {
            // stylelint-disable selector-class-pattern
            .menu-level-1-item .level-1-new {
                position: relative;
                padding: 0 $header-elements-base__padding;

                &:hover {
                    color: $header-navigation-item-hover__color--large;
                }

                &:hover::before {
                    background-color: $header-navigation-item-border__background-color--large;
                }

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    display: block;
                    width: $header-elements-full__width;
                    height: $header-navigation-border__height--large;
                    background-color: $header-navigation-main-container__background-color--large;
                    transform: translateX(-50%);
                    transition: $header-navigation-border__transition--large;
                }

                &::before {
                    width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding * 2});
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: $header-navigation-right-section-separator__top--large;
                    right: 0;
                    left: auto;
                    width: $header-navigation-right-section-separator__width--large;
                    height: $header-navigation-right-section-separator__height--large;
                    background-color: $header-navigation-separator__background-color--large;
                    transform: translateY(-50%);
                }
            }

            .menu-level-1-item {
                &:last-child {
                    .level-1-new {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &-level-1-container {
            position: static;
            padding: 0;
            margin: 0;
            transition: $header-menu__transition;
        }

        &-level-1-wrapper {
            @include flexbox();
            @include align-items(center);
            height: $header-navigation__height--large;
        }

        &-level-1-content {
            margin: 0;
        }

        &-level-2-container {
            position: absolute;
            z-index: 1;
            top: $header-navigation__height--large;
            left: 0;
            width: $header-elements-full__width;
            height: auto;
            padding: 0;
            margin: 0;
            background-color: $header-navigation-dropdown__background-color--large;

            &:focus-visible {
                outline: none; // Fixes Chrome user-agent style which adds extra boxing wrap.
            }
        }

        &-level-2-wrapper {
            position: relative;
        }

        &-level-2-item {
            &.selected {
                > a {
                    color: $header-navigation-item-hover__color--large;

                    &::after {
                        background-color: $header-navigation-item-border__background-color--large;
                    }
                }
            }
        }

        &-level-2-inner-wrapper {
            @include flexbox();
            @include flex-wrap(wrap);
            padding: $header-navigation-second-level-inner__padding--large;

            // stylelint-disable selector-max-compound-selectors
            .second {
                li:first-of-type a {
                    border-top: $border solid $white;
                }
            }

            li {
                a {
                    display: block;
                    padding: $header-navigation-second-level-links__padding--large;
                    border-bottom: $header-navigation-second-level-links__border--large;
                    color: $header-navigation-second-level-links__color--large;
                    font-size: $header-navigation-link__font-size--large;
                    font-weight: $header-navigation-second-level-links__font-weight--large;
                    line-height: $header-navigation-link__line-height--large;

                    &.hide-brands-container {
                        display: none;
                    }
                }


                &.menu-view-brands-page {
                    padding-top: $header-navigation-view-brands-page__padding-top--large;

                    > a {
                        border-bottom: none;
                        color: $header-navigation-view-brands-page__color--large;
                        font-weight: $header-navigation-first-categpry-level-link__font-weight--large;
                        text-decoration: $header-navigation-view-brands__text-decoration--large;
                    }
                }
            }

            .menu-category-level-2 {
                @include flex-direction(row);

                li {
                    @include flex(0 0 50%);

                    &:first-of-type a {
                        border-top: $border solid $white;
                    }

                    &:nth-child(3) a {
                        border-top: $border solid $white;
                    }

                    a {
                        width: 80%;
                        font-size: $header-navigation-subcategory-link__font-size--large;
                        font-weight: $header-navigation-first-categpry-level-link-bold__font-weight--large;
                    }
                }
            }
        }

        &-view-brands-page {
            > a {
                &:hover::after {
                    display: none;
                }
            }
        }

        &-level-2-content,
        &-dropdown-content,
        &-brands-content,
        &-blocks-content {
            @include flex($header-navigation-blocks__flex--large);
            padding: $header-navigation-second-level-columns__padding--large;
            margin: 0;
        }

        &-level-2-content-category {
            @include flex(0 0 50%);
        }

        &-dropdown-content {
            margin-left: auto;
        }

        &-level-2-content {
            padding-left: 0;

            &.second {
                .menu-category-level-2 .menu-level-2-item li:first-of-type a {
                    border-top: $border solid $white;
                }
            }
        }


        &-blocks-content {
            padding-right: 0;
        }

        &-pages-container {
            height: $header-elements-full__height;
            margin: 0;
            margin-left: auto;

            .content-asset {
                height: $header-elements-full__height;
            }
        }

        &-brands-list {
            padding: 0;
            margin: 0;

            li {
                list-style-type: none;
            }
        }

        &-dropdown-labels {
            padding: $header-navigation-dropdown-labels__padding--large;

            &::after {
                display: none;
            }

            &.second-label {
                display: block;
                height: 4rem;
                border-bottom: none;

            }
        }

        &-content-blocks {
            @include flexbox();
            @include justify-content(space-between);
            @include flex-wrap(wrap);

            > * {
                &:first-child {
                    position: relative;
                    width: $header-elements-full__width;
                    padding-bottom: $header-navigation-first-content-block__padding-bottom--large;

                    .text-on-image {
                        @include get-font('large');
                        position: absolute;
                        top: 50%;
                        left: 0;
                        text-align: center;
                        transform: translateY(-50%);
                    }
                }

                &:not(:first-child) {
                    @include flex($header-navigation-not-first-content-block__flex--large);
                    position: relative;
                    width: $header-navigation-content-block-small__width--large;
                    padding: 0;

                    .text-on-image {
                        @include get-font('compact');
                        position: absolute;
                        top: 50%;
                        text-align: center;
                        transform: translateY(-50%);
                    }
                }

                &:nth-child(2) {
                    padding-right: $header-navigation-second-level-blocks__padding-right--large;

                    .text-on-image {
                        left: - $header-navigation-second-level-blocks__padding-right--large / 2;
                    }
                }

                &:nth-child(3) {
                    padding-left: $header-navigation-second-level-blocks__padding-left--large;

                    .text-on-image {
                        left: $header-navigation-second-level-blocks__padding-left--large / 2;
                    }
                }
            }

            > * > * {
                width: $header-elements-full__width;
                object-fit: contain;
            }
        }
    }

    template + .menu-level-1-item .level-1-new {
        padding-left: 0 !important;

        &::before {
            left: 0 !important;
            width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding}) !important;
            transform: none !important;
        }
    }
}

// IE compatibility
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .menu-level-2-inner-wrapper {
        @include flex-wrap(nowrap);
    }
}
