// =============================================================================
//
//  Form Fields Variables
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$form-group__margin-bottom: $base-margin * 1.8;
$form-group-header__margin-bottom: $base-margin * 0.8;

$form-control-label__color: $grey;

$invalid-feedback__margin-top: $base-margin * 0.6;
$invalid-feedback__color: $black;

$form-control-checkbox-title__height: 2rem;
$form-control-checkbox-title__padding-left: $base-padding * 4.3;
$form-control-checkbox-title__color: $black;

$form-control-radio-title__height: 2.2rem;
$form-control-radio-title__padding-left: $base-padding * 3.2;
$form-control-radio-title__color: $black;

$form-error-message__margin-bottom: $base-margin * 1.8;
$form-error-message__color: $black;
