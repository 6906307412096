// =============================================================================
//
//  Header Top
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

.header-top {
    @include flexbox();
    @include align-items(end);
    @include justify-content(space-between);
    position: relative;
    height: $header-top__height;
    background-color: $header-top__background-color;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.1rem;
        left: 0;
        width: $header-elements-full__width;
        height: 0.2rem;
        background-color: $header-top__background-color;
    }

    &-container {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        padding: $header-main-container__padding;
    }

    &-locales {
        @include flexbox();
        @include align-items(flex-end);
        @include justify-content(flex-end);
        width: 100%;
        height: $header-top-locales__height;
        margin-top: $base-margin * 0.9;

        &-flags {
            &-img {
                height: $header-top-locales-flags-img__height;
            }

            &-link {
                text-decoration: none;

                &:first-child {
                    margin-right: $base-margin;
                }

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }

        }
    }

    &-usps-links {
        @include flexbox();
        @include align-items(center);
        padding: 0;
        margin: 0;
    }

    &-icon-list {
        display: none;
        margin-right: $header-top-icons__margin-right;

        &.show-for-mobile {
            display: inline-block;
        }
    }

    &-icon {
        @include get-font('mini-xsmall');
        margin: $header-top-icons__margin;
        color: $header-top-icons__color;

        &.icon-delivery-filled {
            &::before {
                @include get-font('small');
                line-height: $header-top-return-con__line-height;
            }
        }
    }

    &-text {
        @include get-font('mini');
        @include inline-flexbox();
        @include align-items(center);
        @include flex-direction(row);
        color: $header-top-text__color;
        text-decoration: none;
        transition: $header-links__transition;
    }

    &-customer-service {
        @include get-font('mini');
        border-bottom: $header-top-link-name__border-bottom;
        color: $header-top-text__color;
        text-decoration: none;
        transition: $header-links__transition;

        &:hover {
            border-color: $header-top-link-name-hover__border-color;
            color: $header-top-link-name-hover__color;
        }
    }
}

.price-header {
    @include get-font('micro');
    @include flexbox();
    @include align-items(center);
    height: 1.9rem;
    margin-left: $base-margin * 0.5;
    color: $black;

    &.empty-cart {
        display: none;
    }
}
