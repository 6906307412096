// =============================================================================
//
//  Cookie Banner Desktop
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .cookie-message-banner {
        max-height: 70vh;

        .message-banner {
            @include get-font('compact');
            padding: ($base-padding * 2) ($base-padding * 21) ($base-padding * 2) ($base-padding * 2);

            &-row {
                @include flexbox();
                @include flex-flow(row);
                @include flex-wrap(wrap);
                @include justify-content(space-between);

                &.align-center {
                    @include align-items(center);
                }
            }

            &-column {
                padding: ($base-padding * 2);
                margin: 0 (-$base-margin * 2);

                &.col-two {
                    width: 20%;
                }

                &.col-four {
                    width: 32%;
                }

                &.col-six {
                    width: 50%;
                }

                &.col-ten {
                    width: 80%;
                }

                &.buttons {
                    padding: ($base-padding * 2) 0;
                    margin: 0;
                }
            }
        }

        .save-cookie-button {
            margin-top: 0;
        }

        h3 {
            @include get-font('xlarge');
            padding-bottom: $base-padding * 4;
            border-bottom: $border solid $grey;
        }

        h4 {
            margin: ($base-margin * 3) 0;
        }

        p,
        a {
            @include get-font('compact');
            line-height: $cookie-banner-message-banner__line-height--large;
        }

        p + p {
            margin-top: 0;
        }
    }

    .cookie-details-wrapper,
    .cookie-policy-wrapper,
    .cookie-integrity-wrapper {
        max-height: 70vh;

        .banner-details-buttons {
            &-save {
                button {
                    width: 50%;
                }
            }
        }

        .message-banner {
            @include get-font('compact');
            padding: ($base-padding * 2) ($base-padding * 21) ($base-padding * 2) ($base-padding * 2);

            &-row {
                @include flexbox();
                @include flex-flow(row);
                @include flex-wrap(wrap);
                @include justify-content(space-between);

                &.align-center {
                    @include align-items(center);
                }
            }

            &-column {
                padding: ($base-padding * 2);
                margin: 0 (-$base-margin * 2);

                &.col-two {
                    width: 20%;
                }

                &.col-four {
                    width: 32%;
                }

                &.col-six {
                    width: 50%;
                }

                &.col-ten {
                    width: 80%;
                }

                &.buttons {
                    padding: ($base-padding * 2) 0;
                    margin: 0;
                }

                &.accordion {
                    margin-top: $base-margin * 2.5;
                }
            }
        }

        h3 {
            @include get-font('xlarge');
        }

        h4 {
            margin: ($base-margin * 3) 0;
        }

        p,
        a {
            @include get-font('compact');
            line-height: $cookie-banner-message-banner__line-height--large;
        }
    }

    .cookie-policy-wrapper,
    .cookie-integrity-wrapper {
        .message-banner {
            p {
                margin-bottom: $base-margin;
            }

            h4 {
                margin: 0 0 $base-margin;
            }
        }
    }
}
