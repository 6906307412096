// =============================================================================
//
//  Header Menu Navigation Medium Large
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width-x-large) and (max-width: $desktop-width-large) {
    .menu {
        &-main {
            a {
                font-size: $header-navigation-category-link__font-size-small--medium-large;
                padding: 0 $base-padding !important;
            }
        }

        // stylelint-disable selector-class-pattern
        &-category-level-1 .menu-level-1-item .level-1-new {
            padding: 0 $base-padding;
        }

        &-pages-list {
            > .menu-page-item:first-child {
                > .menu-page-link {
                    padding: 0 $base-padding;
                    font-size: $header-navigation-category-link__font-size-small--medium-large;

                    &::before {
                        width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding--medium-large});
                    }
                }
            }
        }

        &-category-level-1 {
            > li {
                > a {
                    font-size: $header-navigation-category-link__font-size--medium-large;
                }
            }
        }
    }
}
