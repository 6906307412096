// =============================================================================
//
//  Toast Messages
//
//  @version    0.0.1
//  @package    Star Nutrition
//
// =============================================================================

/**
 * Contains override styles for the toast messages specific to the website design.
 */

.toast-message {
    @include get-font('small');
}

/* stylelint-disable selector-max-id */
#toast-container {
    & > .toast.toast-error { // Important to keep the current selector.
        padding: 20px 40px;
        background-image: none; // Hides icon on the left side.
        text-align: justify;
    }

    &.toast-top-center > .toast,
    &.toast-bottom-center > .toast {
        width: 400px;
        margin-bottom: 20px;
    }
}
