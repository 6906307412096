// =============================================================================
//
//  Product Tile Mobile Views
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Bojan Brkic <bojan.brkic@impactcommerce.se>
//
// =============================================================================

@import '../themes/default/components/productTiles';

// Card View Mobile Layout
@media screen and (max-width: $tablet-width) {
    .card-view,
    .carousel-inner.slick-slider {
        .product-tile {
            @include flex-flow(column);
            height: 100%;

            &-image {
                width: 100%;
                height: auto;

                &__container {
                    position: relative;
                    width: 100%;
                    padding-top: 4.5rem;
    
                    &-item {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &-num-of-variants-wrapper-mobile {
                @include get-font('tiny');
                position: absolute;
                right: 0;
                bottom: $card-view-position__bottom;
            }

            &-description {
                position: static;
                margin: 0;

                .product-tile-rating,
                .gmf-rating-stars {
                    max-width: $card-view-gmf-stars__width;
                    white-space: nowrap;
                }
            }

            &-top-rating-variant {
                order: -1;
                margin: $card-view-rating__margin;
            }

            &-CTA {
                @include flex-flow(row wrap);
                @include align-items(flex-start);
                padding-top: 2.5rem;
            }

            .price-ref {
                @include justify-content(flex-start);
                margin-bottom: 0;
                text-align: left;

                .price-ref__stmt-container,
                .price-ref__stmt {
                    width: 100%;
                }

                &-wrapper {
                    order: 1;
                    width: 100%;
                    min-height: $card-view-ref-wrapper__height;
                    margin-top: $price-ref__margin-top--large;
                }
            }

            &-promotions-right {
                position: absolute;
                top: 0;
                left: 0;

                .promotions-right {
                    @include flexbox;
                    @include flex-flow(column);
                    @include align-items(stretch);
                }

                .promotion {
                    &.sale {
                        min-width: $product-tile-promotion__min-width--large;
                    }
                }
            }
        }
    }

    .carousel-inner.slick-slider {
        .product-tile {
            &-image__container {
                padding-top: 6.5rem;
            }

            &-promotions-right {
                top: 2rem;
                left: 1rem;
            }
        }
    }
}
