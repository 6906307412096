// =============================================================================
//
//  Static Page
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .static-page {
        margin: $static-page__margin--large;

        &-wrapper {
            @include flexbox();
            @include justify-content(space-between);
            padding: $static-page-wrapper__padding--large;
        }

        &-menu-container {
            @include flex($static-page-menu__flex--large);
            padding: 0;
        }

        &-body-container {
            @include flex($static-page-body__flex--large);
            max-width: $static-page-body-container__max-width--large;
            margin: 0;

            .wrapper {
                padding: 0;
            }
        }

        &-menu-item-content {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        &-menu-sub-item {
            padding: $static-page-menu-sub-item__padding;
            border-top: $static-page-menu-sub-item__border-top;

            &.active {
                border-color: $static-page-menu-sub-item-active__border-color;
                font-weight: $static-page-menu-sub-item-active__font-weight;
            }
        }

        &-menu-link {
            text-decoration: none;
        }
    }

    &-menu-sub-item {
        padding: $static-page-menu-sub-item__padding;
        border-top: $static-page-menu-sub-item__border-top;

        &.active {
            border-color: $static-page-menu-sub-item-active__border-color;
            font-weight: $static-page-menu-sub-item-active__font-weight;
        }
    }
}
