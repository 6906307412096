// =============================================================================
//
//  Inputs
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

// -----------------------------------------------------------------------------
//  Quantity Form
// -----------------------------------------------------------------------------

@media screen and (min-width: $desktop-width) {
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='url'],
    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    textarea,
    select {
        @include get-font('medium');
    }

    .quantity-form {
        .quantity {
            @include get-font('compact');
            width: $quantity__width--large;
            height: $quantity__height--large;
            padding: $quantity__padding--large;
        }

        .quantity-icon {
            padding: $quantity-icon__padding--large;
            font-size: $quantity-icon__font-size--large;

            &::before {
                padding: $quantity-icon-before__padding--large;
            }
        }
    }
}
