// =============================================================================
//
//  Form Fields
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/formFields';

.form-group {
    position: relative;
    margin-bottom: $form-group__margin-bottom;

    // -------------------------------------------------------------------------
    //  Header
    // -------------------------------------------------------------------------

    &-header {
        margin-bottom: $form-group-header__margin-bottom;
    }

    // -------------------------------------------------------------------------
    //  Label
    // -------------------------------------------------------------------------

    .form-control-label {
        @include get-font('mini');

        color: $form-control-label__color;
    }

    // -------------------------------------------------------------------------
    //  Required Indicator
    // -------------------------------------------------------------------------

    .required-indicator::before {
        content: '*';
    }

    // -------------------------------------------------------------------------
    //  Error Message
    // -------------------------------------------------------------------------

    .invalid-feedback {
        @include get-font('xsmall');
        margin-top: $invalid-feedback__margin-top;

        color: $invalid-feedback__color;
    }

    // -------------------------------------------------------------------------
    //  Checkbox & Radio
    // -------------------------------------------------------------------------

    .form-control-checkbox-title {
        @include get-font('small');

        display: table-cell;
        height: $form-control-checkbox-title__height;
        padding-left: $form-control-checkbox-title__padding-left;
        color: $form-control-checkbox-title__color;
        vertical-align: middle;
    }

    .form-control-radio-title {
        @include get-font('xsmall');

        display: table-cell;
        height: $form-control-radio-title__height;
        padding-left: $form-control-radio-title__padding-left;
        color: $form-control-radio-title__color;
        vertical-align: middle;
    }
}

// -----------------------------------------------------------------------------
//  Form - Error Message
// -----------------------------------------------------------------------------

.form-error-message {
    @include get-font('xsmall');
    margin-bottom: $form-error-message__margin-bottom;

    color: $form-error-message__color;
}
